import React from "react"
import Layout from '../components/global/layout.js'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import { Helmet } from "react-helmet"
import projectsStyles from '../style/projects.module.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "../components/global/image.js"

import ShortInfo from "../components/projects/projects.shortinfo.js"
import Description from "../components/projects/projects.description.js"
import Navigation from "../components/projects/projects.navigation.js"

import img1 from "../img/projects/Teaser_EM.jpg"



const EMTicker = () => {

    const intl = useIntl()

    const subTitleObject = <p>{intl.formatMessage({ id: "Projects.clients.infinitymedia" })}</p>
    const subTitle = subTitleObject.props.children

    return (

        <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | EM Live-Ticker </title>
                <meta name="description" content="Full-stack Software Design und Development - Ein Einblick in unsere Lieblingsprojekte"></meta>
            </Helmet>

            <LandingSection
                h1={<FormattedMessage id="Project.h1" />}
                title={<FormattedMessage id="Projects.emticker" />}
                subtitle={subTitle}
                showCallToAction={false}
            />

            <ContentSection>

                <ShortInfo
                    client={<FormattedMessage id="Projects.clients.infinitymedia" />}
                    year={2021}
                    services={[
                        <FormattedMessage id="Services.development" />,
                        <FormattedMessage id="Services.infrastructure" />,
                        <FormattedMessage id="Services.consulting" />,
                    ]}
                />
                <Description
                    project={
                        <>
                            <FormattedMessage id="Projects.emticker.project.1" />
                            <AniLink cover bg="#F2F2F2" duration={1} direction="left" to={"../project_digitalsignage"} className={projectsStyles.gridContent}><FormattedMessage id="Projects.emticker.project.a" /></AniLink>
                            <FormattedMessage id="Projects.emticker.project.2" />
                        </>
                    }
                    contribution={<FormattedMessage id="Projects.emticker.contribution" />}
                    technologies={[
                        <FormattedMessage id="Projects.technologies.ts" />,
                        <FormattedMessage id="Projects.technologies.node" />,
                        <FormattedMessage id="Projects.technologies.vue.js" />,
                        <FormattedMessage id="Projects.technologies.typeorm" />,
                        <FormattedMessage id="Projects.technologies.postgre" />,]}
                    img1={<Image className={projectsStyles.projectImage} src={img1} alt="Projects.emticker.img1.alt" />}
                />

                <Navigation
                    prevName={<FormattedMessage id="Projects.digitalsignage" />}
                    prevClient={<FormattedMessage id="Projects.clients.infinitymedia" />}
                    prevYear="2020"
                    prevUrl="../project_digitalsignage"

                    nextName={<FormattedMessage id="Projects.exhibitorcatalogue" />}
                    nextClient={<FormattedMessage id="Projects.clients.tradex" />}
                    nextYear="2021"
                    nextUrl="../project_exhibitorcatalogue"
                />

            </ContentSection>
        </Layout>


    )
}

export default EMTicker
